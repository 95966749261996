import classNames from 'classnames/bind';
import React from 'react';

import styles from './TopBar.module.scss';

import AccountCircle from '@/components/ui/AccountCircle';
import Bar from '@/components/ui/Bar';
import MapSelector from '@/components/ui/map/MapSelector';
import ZonesSelector from '@/components/ui/map/ZonesSelector';
import Wordmark from '@/components/ui/Wordmark';

const cx = classNames.bind(styles);

const TopBar = () => {
  return (
    <div className={cx('container')}>
      <div className={cx(['side', 'left'])}>
        <img src={require('@/assets/images/logo.png')} alt="M1UCS" />
        <Wordmark title="Mission Hub" />
      </div>
      <div className={cx('center')}>
        <MapSelector />
        {process.env.REACT_APP_USE_ZONE_VWORLD === 'true' && (
          <>
            <Bar height={20} />
            <ZonesSelector />
          </>
        )}
      </div>
      <div className={cx(['side', 'right'])}>
        <AccountCircle />
      </div>
    </div>
  );
};

export default TopBar;
