import { createActions } from 'redux-actions';

export default createActions(
  {
    SET_SPECS: (specs) => ({ specs }),
    SET_CONFIG: (config) => ({ config }),
    CHANGE_MODE: (mode) => ({ mode }),
    CHANGE_NAME: (name) => ({ name }),
    LOAD: (mission) => mission,
    SAVE: () => ({}),
    // Waypoint
    APPEND_WAYPOINT: (waypoint) => ({ waypoint }),
    INSERT_WAYPOINT: (index, waypoint) => ({ index, waypoint }),
    MOVE_WAYPOINT: (id, waypoint) => ({ id, waypoint }),
    EDIT_WAYPOINT: (index, name, value) => ({ index, name, value }),
    EDIT_ALL_WAYPOINTS: (name, value) => ({ name, value }),
    // Survey
    APPEND_SURVEY: (boundary, positions) => ({ boundary, positions }),
    EDIT_SURVEY: (index, values) => ({ index, values }),
    // Gripper
    APPEND_GRIPPER: () => ({}),
    // Set Relay
    APPEND_SET_RELAY: () => ({}),
    // Cycle Relay
    APPEND_CYCLE_RELAY: () => ({}),
    // Set Servo
    APPEND_SET_SERVO: () => ({}),
    // Cycle Servo
    APPEND_CYCLE_SERVO: () => ({}),
    // Delay Until
    APPEND_DELAY_UNTIL: () => ({}),
    // Jump To Item
    APPEND_JUMP_TO_ITEM: () => ({}),
    // Change Altitude
    APPEND_CHANGE_ALTITUDE: () => ({}),
    // Change Speed
    APPEND_CHANGE_SPEED: (speed) => ({ speed }),
    EDIT_CHANGE_SPEED: (index, name, value) => ({ index, name, value }),
    // Wait For Distance
    APPEND_WAIT_FOR_DISTANCE: () => ({}),
    // Wait For Yaw
    APPEND_WAIT_FOR_YAW: () => ({}),
    // Trigger Parachute
    APPEND_TRIGGER_PARACHUTE: () => ({}),
    // Guided Enable
    APPEND_GUIDED_ENABLE: () => ({}),
    // Guided Limits
    APPEND_GUIDED_LIMITS: () => ({}),
    // Autotune Enable
    APPEND_AUTOTUNE_ENABLE: () => ({}),
    // Set Region Of Interest None
    APPEND_SET_ROI_NONE: () => ({}),
    // Mission
    RESET_MISSION_ITEMS: () => ({}),
    REMOVE_MISSION_ITEM: (index) => ({ index }),
    SHIFT_MISSION_ITEM: (index, offset) => ({ index, offset }),
    UPDATE_MISSION_ITEM: (index, name, value) => ({ index, name, value }),
    CHANGE_MISSION_ITEM_TYPE: (index, type) => ({ index, type }),
    APPEND_RETURN_TO_LAUNCH: () => ({}),
  },
  { prefix: 'EDITOR' }
);
