import produce from 'immer';
import { handleActions } from 'redux-actions';

const initialState = [];

export default handleActions(
  {
    'GUIDE_ROBOTS/LOAD': (state, action) => {
      const { robots } = action.payload;

      return robots.map((robot) => {
        robot.isActive = false;
        robot.isShow = false;
        return robot;
      });
    },
    'GUIDE_ROBOTS/ACTIVATE': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;

        const index = state.findIndex(({ id }) => id === robotId);
        draft[index].isActive = true;
        draft[index].isShow = true;
      }),
    'GUIDE_ROBOTS/TOGGLE_SHOW': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;

        const index = state.findIndex(({ id }) => id === robotId);
        draft[index].isShow = !state[index].isShow;
      }),
  },
  initialState
);
