import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { memo, useMemo, useState } from 'react';
import {
  RiCompasses2Line,
  RiSpeedUpLine,
  RiCameraLine,
  RiDvdLine,
  RiVipDiamondLine,
  RiGitPullRequestLine,
  RiTimerLine,
  RiRestartLine,
  RiArrowUpDownLine,
  RiPinDistanceLine,
  RiRemoteControlLine,
  RiToolsLine,
  RiListSettingsLine,
  RiToggleLine,
  RiFocus3Line,
} from 'react-icons/ri';
import { useStore } from 'react-redux';

import CondDistance from './CondDistance';
import CondYaw from './CondYaw';
import DoAutotuneEnable from './DoAutotuneEnable';
import DoChangeSpeed from './DoChangeSpeed';
import DoGripper from './DoGripper';
import DoGuidedLimits from './DoGuidedLimits';
import DoJump from './DoJump';
import DoParachute from './DoParachute';
import DoRepeatRelay from './DoRepeatRelay';
import DoRepeatServo from './DoRepeatServo';
import DoSetCamTriggDist from './DoSetCamTriggDist';
import DoSetRelay from './DoSetRelay';
import DoSetRoiLocation from './DoSetRoiLocation';
import DoSetRoiNone from './DoSetRoiNone';
import DoSetServo from './DoSetServo';
import styles from './index.module.scss';
import NavContinueAndChangeAlt from './NavContinueAndChangeAlt';
import NavDelay from './NavDelay';
import NavGuidedEnable from './NavGuidedEnable';
import NavLand from './NavLand';
import NavLoiterTime from './NavLoiterTime';
import NavLoiterToAlt from './NavLoiterToAlt';
import NavLoiterTurns from './NavLoiterTurns';
import NavReturnToLaunch from './NavReturnToLaunch';
import NavTakeoff from './NavTakeoff';
import NavWaypoint from './NavWaypoint';

import AccordionCaret from '@/components/ui/AccordionCaret';
import Marker from '@/components/ui/map/Marker';

const cx = classNames.bind(styles);

const Item = ({ label, isCurrent, data: missionItem }) => {
  const store = useStore();
  const [isOpen, setIsOpen] = useState(false);

  const Icon = useMemo(() => {
    switch (missionItem.type) {
      case 'condDistance':
        return RiPinDistanceLine;
      case 'condYaw':
        return RiRestartLine;
      case 'doAutotuneEnable':
        return RiToolsLine;
      case 'doChangeSpeed':
        return RiSpeedUpLine;
      case 'doGripper':
        return RiCompasses2Line;
      case 'doGuidedLimits':
        return RiListSettingsLine;
      case 'doJump':
        return RiGitPullRequestLine;
      case 'doParachute':
        return RiVipDiamondLine;
      case 'doSetCamTriggDist':
        return RiCameraLine;
      case 'doRepeatRelay':
      case 'doSetRelay':
        return RiToggleLine;
      case 'doSetRoiNone':
        return RiFocus3Line;
      case 'doRepeatServo':
      case 'doSetServo':
        return RiDvdLine;
      case 'navContinueAndChangeAlt':
        return RiArrowUpDownLine;
      case 'navDelay':
        return RiTimerLine;
      case 'navGuidedEnable':
        return RiRemoteControlLine;

      // Use default marker icon below for these types.
      case 'doSetRoiLocation':
      case 'navLand':
      case 'navLoiterTime':
      case 'navLoiterToAlt':
      case 'navLoiterTurns':
      case 'navReturnToLaunch':
      case 'navTakeoff':
      case 'navWaypoint':
      default:
        return;
    }
  }, [missionItem.type]);

  const title = useMemo(() => {
    switch (missionItem.type) {
      case 'condDistance':
        return 'Wait For Distance';
      case 'condYaw':
        return 'Wait For Yaw';
      case 'doAutotuneEnable':
        switch (missionItem.data.enable) {
          case 0:
            return 'Autotune Disable';
          case 1:
            return 'Autotune Enable';
          default:
            return;
        }
      case 'doChangeSpeed':
        return 'Change Speed';
      case 'doGripper':
        return 'Gripper';
      case 'doGuidedLimits':
        return 'Guided Limits';
      case 'doJump':
        return 'Jump To Item';
      case 'doParachute':
        switch (missionItem.data.action) {
          case 0:
            return 'Parachute Disable';
          case 1:
            return 'Parachute Enable';
          case 2:
            return 'Parachute Release';
          default:
            return;
        }
      case 'doRepeatRelay':
        return 'Cycle Relay';
      case 'doSetRoiLocation':
        return 'Region Of Interest';
      case 'doSetRoiNone':
        return 'Cancel ROI';
      case 'doRepeatServo':
        return 'Cycle Servo';
      case 'doSetCamTriggDist':
        return 'Set Camera Trigger Distance';
      case 'doSetRelay':
        return 'Set Relay';
      case 'doSetServo':
        return 'Set Servo';
      case 'navContinueAndChangeAlt':
        return 'Change Altitude';
      case 'navDelay':
        return 'Delay Until';
      case 'navGuidedEnable':
        switch (missionItem.data.enable) {
          case 0:
            return 'Guided Mode Disable';
          case 1:
            return 'Guided Mode Enable';
          default:
            return;
        }
      case 'navLand':
        return 'Land';
      case 'navLoiterTime':
        return 'Loiter (Time)';
      case 'navLoiterToAlt':
        return 'Loiter (Altitude)';
      case 'navLoiterTurns':
        return 'Loiter (Turns)';
      case 'navReturnToLaunch':
        return 'Return To Launch';
      case 'navTakeoff':
        return 'Takeoff';
      case 'navWaypoint':
        return 'Waypoint';
      default:
        return;
    }
  }, [missionItem.type]);

  useUpdateEffect(() => {
    setIsOpen(isCurrent);
  }, [isCurrent]);

  const toggle = (e) => {
    const isSelect = e.target.closest('select');
    if (isSelect) return;

    setIsOpen(!isOpen);
  };

  const color = useMemo(() => {
    const targetRobotId = store.getState().robot.targetRobotIds[0];
    const targetRobot = store.getState().robot.robots.find(({ id }) => id === targetRobotId);
    return targetRobot.color;
  }, []);

  return (
    <div className={cx('container')}>
      <div className={cx('header')} onClick={toggle}>
        <div className={cx('left')}>
          {!Icon && (
            <div className={cx('marker')}>
              <Marker color={color} data={{ label }} />
            </div>
          )}
          {Icon && (
            <div className={cx('icon')}>
              <Icon size={18} color="white" />
            </div>
          )}
          <div className={cx('title')}>{title}</div>
        </div>
        <div className={cx('right')}>
          {isCurrent && <div className={cx('current')}>Current</div>}
          <AccordionCaret up={isOpen} size={32} />
        </div>
      </div>
      <div className={cx(['body', { open: isOpen }])}>
        <div className={cx('inner')}>
          {missionItem.type === 'condDistance' && <CondDistance data={missionItem} />}
          {missionItem.type === 'condYaw' && <CondYaw data={missionItem} />}
          {missionItem.type === 'doAutotuneEnable' && <DoAutotuneEnable data={missionItem} />}
          {missionItem.type === 'doChangeSpeed' && <DoChangeSpeed data={missionItem} />}
          {missionItem.type === 'doGripper' && <DoGripper data={missionItem} />}
          {missionItem.type === 'doGuidedLimits' && <DoGuidedLimits data={missionItem} />}
          {missionItem.type === 'doJump' && <DoJump data={missionItem} />}
          {missionItem.type === 'doParachute' && <DoParachute data={missionItem} />}
          {missionItem.type === 'doRepeatRelay' && <DoRepeatRelay data={missionItem} />}
          {missionItem.type === 'doRepeatServo' && <DoRepeatServo data={missionItem} />}
          {missionItem.type === 'doSetCamTriggDist' && <DoSetCamTriggDist data={missionItem} />}
          {missionItem.type === 'doSetRelay' && <DoSetRelay data={missionItem} />}
          {missionItem.type === 'doSetRoiLocation' && <DoSetRoiLocation data={missionItem} />}
          {missionItem.type === 'doSetRoiNone' && <DoSetRoiNone data={missionItem} />}
          {missionItem.type === 'doSetServo' && <DoSetServo data={missionItem} />}
          {missionItem.type === 'navContinueAndChangeAlt' && <NavContinueAndChangeAlt data={missionItem} />}
          {missionItem.type === 'navDelay' && <NavDelay data={missionItem} />}
          {missionItem.type === 'navGuidedEnable' && <NavGuidedEnable data={missionItem} />}
          {missionItem.type === 'navLand' && <NavLand data={missionItem} />}
          {missionItem.type === 'navLoiterTime' && <NavLoiterTime data={missionItem} />}
          {missionItem.type === 'navLoiterToAlt' && <NavLoiterToAlt data={missionItem} />}
          {missionItem.type === 'navLoiterTurns' && <NavLoiterTurns data={missionItem} />}
          {missionItem.type === 'navReturnToLaunch' && <NavReturnToLaunch />}
          {missionItem.type === 'navTakeoff' && <NavTakeoff data={missionItem} />}
          {missionItem.type === 'navWaypoint' && <NavWaypoint data={missionItem} />}
        </div>
      </div>
    </div>
  );
};

export default memo(Item);
