import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { fromLonLat, toLonLat } from 'ol/proj';
import React, { useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { useDispatch, useSelector } from 'react-redux';

import actions from '@/actions';
import Marker from '@/components/ui/map/Marker';
import OlMap from '@/helpers/OlMap';
import EventEmitter from '@/libs/EventEmitter';
import { NotifierService as notifier } from '@/libs/Notifier';
import CoordUtils from '@/utils/CoordUtils';
import { getElevation } from '@/utils/MapUtils';

const Markers = () => {
  const dispatch = useDispatch();
  const missionId = useSelector((state) => state.editor.id);
  const markers = useSelector((state) => state.editor.markers);
  const markersRef = useRef({});
  const isDragging = useRef(false);
  const focusMarkerId = useRef();
  const focusMarkerOffset = useRef();
  const map = OlMap.getMap();

  useUpdateEffect(() => {
    Object.values(markersRef.current).forEach(({ element }) => map.removeOverlay(element));
    markersRef.current = {};

    OlMap.fitBounds(Object.values(markers).map(({ position }) => [position.lng, position.lat]));
  }, [missionId]);

  useUpdateEffect(() => {
    const currMarkerIds = Object.keys(markersRef.current);
    const nextMarkerIds = Object.keys(markers);

    // 마커 추가
    if (currMarkerIds.length < nextMarkerIds.length) {
      const willBeAddedIds = nextMarkerIds.filter((id) => !Object.hasOwn(markersRef.current, id));

      willBeAddedIds.forEach((id) => {
        const markerElement = getMarkerElement(id);
        markersRef.current[id] = { ...markers[id], element: markerElement };
      });
    }
    // 마커 제거
    else if (nextMarkerIds.length < currMarkerIds.length) {
      const willBeRemovedIds = currMarkerIds.filter((id) => !Object.hasOwn(markers, id));

      willBeRemovedIds.forEach((id) => {
        map.removeOverlay(markersRef.current[id].element);
        delete markersRef.current[id];
      });
    }
    // 마커 이동
    else if (nextMarkerIds.length === currMarkerIds.length) {
      nextMarkerIds.forEach((id) => {
        const oldPosition = fromLonLat([markers[id].position.lng, markers[id].position.lat]);
        const newPosition = markersRef.current[id].element.getPosition();

        if (oldPosition[0] !== newPosition[0] || oldPosition[1] !== newPosition[1]) {
          markersRef.current[id].element.setPosition(oldPosition);
        }
      });
    }

    // 나머지 마커 라벨 변경
    Object.keys(markersRef.current).forEach((id) => {
      if (markersRef.current[id].label === markers[id].label) return;

      map.removeOverlay(markersRef.current[id].element);
      const markerElement = getMarkerElement(id);
      markersRef.current[id] = { ...markers[id], element: markerElement };
    });
  }, [markers]);

  const startDrag = (e) => {
    const id = focusMarkerId.current;

    const clickPosition = map.getEventCoordinate(e);
    const markerPosition = markersRef.current[id].element.getPosition();

    focusMarkerOffset.current = {
      x: clickPosition[0] - markerPosition[0],
      y: clickPosition[1] - markerPosition[1],
    };

    document.addEventListener('mousemove', onDrag);
    document.addEventListener('mouseup', endDrag);
  };

  const onDrag = (e) => {
    const id = focusMarkerId.current;

    // 드래그 불가한 마커인 경우
    if (!markersRef.current[id].draggable) {
      removeListeners();
      notifier.error('This marker cannot be moved by dragging.');
      return;
    }

    isDragging.current = true;

    const movePosition = map.getEventCoordinate(e);
    const markerPosition = [
      movePosition[0] - focusMarkerOffset.current.x,
      movePosition[1] - focusMarkerOffset.current.y,
    ];

    markersRef.current[id].element.setPosition(markerPosition);
  };

  const endDrag = () => {
    const id = focusMarkerId.current;

    removeListeners();

    // 드래그인 경우
    if (isDragging.current) {
      isDragging.current = false;

      const markerPosition = markersRef.current[id].element.getPosition();
      const position = CoordUtils.objectFromArray(toLonLat(markerPosition));

      getElevation(position).then((elevation) => {
        const waypoint = { position, elevation };
        dispatch(actions.editor.moveWaypoint(id, waypoint));
      });
    }
    // 클릭인 경우
    else {
      EventEmitter.publish(`missionItem/${id}/toggle`);
    }
  };

  const getMarkerElement = (id) => {
    const element = document.createElement('div');
    createRoot(element).render(<Marker data={markers[id]} />);

    element.addEventListener('mousedown', (e) => {
      focusMarkerId.current = id;
      startDrag(e);
    });
    element.addEventListener('mouseenter', () => {
      element.style.cursor = 'pointer';
    });
    element.addEventListener('mouseleave', () => {
      element.style.cursor = 'default';
    });

    const overlay = OlMap.createOverlay({
      element,
      position: fromLonLat([markers[id].position.lng, markers[id].position.lat]),
      positioning: 'bottom-center',
      insertFirst: false,
    });
    map.addOverlay(overlay);

    return overlay;
  };

  const removeListeners = () => {
    document.removeEventListener('mousemove', onDrag);
    document.removeEventListener('mouseup', endDrag);
  };

  return null;
};

export default Markers;
