import classNames from 'classnames/bind';
import React from 'react';

import styles from './Wordmark.module.scss';

const cx = classNames.bind(styles);

const Wordmark = ({ title }) => {
  return (
    <div className={cx('container')}>
      <div className={cx('app')}>M1UCS</div>
      <div className={cx('title')}>{title}</div>
    </div>
  );
};

export default Wordmark;
