import { createActions } from 'redux-actions';

export default createActions(
  {
    LOAD: (robots) => ({ robots }),
    ACTIVATE: (robotId) => ({ robotId }),
    TOGGLE_SHOW: (robotId) => ({ robotId }),
  },
  { prefix: 'GUIDE_ROBOTS' }
);
