import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import { toLonLat } from 'ol/proj';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import ConfirmSlider from '@/components/ui/ConfirmSlider';
import { MessageContext } from '@/helpers/MessageProvider/ForControlCenter';
import OlMap from '@/helpers/OlMap';
import EventEmitter from '@/libs/EventEmitter';
import { getElevation } from '@/utils/MapUtils';

const cx = classNames.bind(styles);

const Action = ({ data: robots }) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);
  const [isImmediately, setIsImmediately] = useState(true);
  const map = OlMap.getMap();

  useUpdateEffect(() => {
    if (!isImmediately) {
      map.getTargetElement().style.cursor = 'crosshair';
      map.on('click', clickOnMap);
    }

    return () => {
      map.getTargetElement().style.cursor = 'default';
      map.un('click', clickOnMap);
    };
  }, [robots, isImmediately]);

  const clickOnMap = (e) => {
    const [lng, lat] = toLonLat(e.coordinate);

    getElevation({ lat, lng }).then((elevation) => {
      robots.forEach((robot) => {
        publishCommand(robot, 'action/set_home', [[NaN, lat, lng, elevation], []]);
        EventEmitter.publish(`${robot.id}/event/removeGoto`);

        setTimeout(() => publishCommand(robot, 'action/return', [[]]), 2000);
      });

      dispatch(actions.command.toggle());
    });
  };

  const doCommand = () => {
    robots.forEach((robot) => {
      publishCommand(robot, 'action/land/px4', [[]]);
      EventEmitter.publish(`${robot.id}/event/removeGoto`);
    });

    dispatch(actions.command.toggle());
  };

  return (
    <div className={cx('container')}>
      <div className={cx('switch')}>
        <div className={cx(['bar', { left: isImmediately }])} />
        <div className={cx('buttons')}>
          <div className={cx('button')} onClick={() => setIsImmediately(true)}>
            Immediately
          </div>
          <div className={cx('button')} onClick={() => setIsImmediately(false)}>
            Set Home & RTH
          </div>
        </div>
      </div>
      {isImmediately && <ConfirmSlider onConfirm={doCommand} />}
      {!isImmediately && (
        <div className={cx('message')}>
          Click on a location on the map
          <br />
          where you want to land the drone.
        </div>
      )}
    </div>
  );
};

export default Action;
