import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import actions from '@/actions';
import ConfirmSlider from '@/components/ui/ConfirmSlider';
import { MessageContext } from '@/helpers/MessageProvider/ForControlCenter';

const Action = ({ data: robots }) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);

  const doCommand = () => {
    robots.forEach((robot) => {
      publishCommand(robot, 'mission/clear', [[], [], []]);
      dispatch(actions.mission.unload(robot.id));
    });

    dispatch(actions.command.toggle());
  };

  return <ConfirmSlider onConfirm={doCommand} />;
};

export default Action;
