import useMountEffect from '@restart/hooks/useMountEffect';
import React, { useState } from 'react';

import { Status } from '@/components/ui/Dashboard';
import EventEmitter from '@/libs/EventEmitter';

const Component = ({ robotId }) => {
  const [satellites, setSatellites] = useState(null);

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/telemetry`, ({ sats }) => {
      setSatellites(sats);
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return <Status data={{ satellites }} />;
};

export default Component;
