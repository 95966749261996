import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { pointerMove } from 'ol/events/condition';
import Feature from 'ol/Feature';
import { LineString, MultiPoint, Point } from 'ol/geom';
import { Select } from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import { fromLonLat } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { Style, Stroke, Fill, Circle, Text } from 'ol/style';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import actions from '@/actions';
import OlMap from '@/helpers/OlMap';
import CoordUtils from '@/utils/CoordUtils';

const GuidePaths = () => {
  const dispatch = useDispatch();
  const guideMissions = useSelector((state) => state.guideMissions);
  const addedLayer = useRef({});
  const map = OlMap.getMap();

  useUpdateEffect(() => {
    const currMissionIds = Object.keys(addedLayer.current);
    const nextMissionIds = guideMissions.map(({ id }) => id);

    // Guide Mission 추가
    if (currMissionIds.length < nextMissionIds.length) {
      const willBeAddedMissions = guideMissions.filter(({ id }) => !currMissionIds.includes(id));

      willBeAddedMissions.forEach((mission) => {
        const positions = mission.path.map(({ position }) => fromLonLat(CoordUtils.arrayFromObject(position)));

        const path = new Feature(new LineString(positions));
        path.set('missionName', mission.name);
        const points = new Feature(new MultiPoint(positions));

        const source = new VectorSource({ features: [path, points] });
        const layer = new VectorLayer({
          source,
          declutter: true,
          style: (feature) => {
            const geom = feature.getGeometry();

            switch (geom.getType()) {
              // 경로 스타일
              case 'LineString':
                return new Style({
                  stroke: new Stroke({
                    color: [0, 0, 0, 0.5],
                    width: 4,
                  }),
                });

              // 경로점 스타일
              case 'MultiPoint':
                return geom.getCoordinates().map((coord, index) => {
                  return new Style({
                    geometry: new Point(coord),
                    image: new Circle({
                      radius: 10,
                      fill: new Fill({ color: [0, 0, 0, 0.5] }),
                    }),
                    text: new Text({
                      text: `${index + 1}`,
                      font: 'bold 9px "Noto Sans KR", sans-serif',
                      fill: new Fill({ color: 'white' }),
                    }),
                  });
                });

              default:
                break;
            }
          },
        });
        map.addLayer(layer);
        addedLayer.current[mission.id] = layer;

        // Mouse over 시 강조
        const handleHover = new Select({
          condition: pointerMove,
          layers: [layer],
          filter: (feature) => feature.getGeometry().getType() === 'LineString', // 경로만 대상
        });
        handleHover.on('select', (e) => {
          if (e.selected.length) {
            const { missionName } = e.selected[0].values_;
            dispatch(actions.callout.setText(missionName));
          } else {
            dispatch(actions.callout.setText(null));
          }
        });
        map.addInteraction(handleHover);
      });
    }
    // Guide Mission 제거
    else {
      const willBeRemovedIds = currMissionIds.filter((id) => !nextMissionIds.includes(id));

      willBeRemovedIds.forEach((id) => {
        map.removeLayer(addedLayer.current[id]);
        delete addedLayer.current[id];
      });
    }
  }, [guideMissions]);

  return null;
};

export default GuidePaths;
