import classNames from 'classnames/bind';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import ConfirmSlider from '@/components/ui/ConfirmSlider';
import { MessageContext } from '@/helpers/MessageProvider/ForControlCenter';
import sleep from '@/utils/Sleep';

const cx = classNames.bind(styles);

const Action = ({ data: robots }) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);
  const [inputs, setInputs] = useState([
    { number: 9, pwm: 1800 },
    { number: 10, pwm: 1800 },
  ]);

  const handleChange = (e) => {
    const [key, index] = e.target.name.split('.');

    const nextInputs = [...inputs];
    nextInputs[index][key] = Number(e.target.value);
    setInputs(nextInputs);
  };

  const doCommand = () => {
    robots.forEach(async (robot) => {
      publishCommand(robot, 'action/set_servo', [[inputs[0].number, inputs[0].pwm]]);
      await sleep(500);
      publishCommand(robot, 'action/set_servo', [[inputs[1].number, inputs[1].pwm]]);
    });

    dispatch(actions.command.toggle());
  };

  return (
    <div className={cx('container')}>
      <div className={cx('form')} style={{ width: 240 }}>
        <label>
          Instance Number
          <div className={cx('fieldWrapper')}>
            <input name="number.0" type="number" value={inputs[0].number} onChange={handleChange} />
            <input name="number.1" type="number" value={inputs[1].number} onChange={handleChange} />
          </div>
        </label>
        <label>
          PWM
          <div className={cx('fieldWrapper')}>
            <input name="pwm.0" type="number" value={inputs[0].pwm} onChange={handleChange} />
            <input name="pwm.1" type="number" value={inputs[1].pwm} onChange={handleChange} />
          </div>
        </label>
      </div>
      <ConfirmSlider onConfirm={doCommand} />
    </div>
  );
};

export default Action;
