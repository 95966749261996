import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useContext, useState, useRef } from 'react';
import { RiLoader2Line, RiCheckboxCircleFill } from 'react-icons/ri';

import styles from '../console.module.scss';

import { MessageContext } from '@/helpers/MessageProvider/ForControlCenter';
import EventEmitter from '@/libs/EventEmitter';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const Panel = ({ robot }) => {
  const { publishCommand } = useContext(MessageContext);
  const [isStarted, setStarted] = useState(false);
  const [steps, setSteps] = useState([
    { isReady: false, isDone: false },
    { isReady: false, isDone: false },
    { isReady: false, isDone: false },
    { isReady: false, isDone: false },
    { isReady: false, isDone: false },
    { isReady: false, isDone: false },
  ]);
  const [stepIndex, setStepIndex] = useState();
  const [isSuccess, setSuccess] = useState();
  const containerRef = useRef();
  const subscribeToken = useRef();

  useMountEffect(() => {
    return () => {
      unsubscribe();
    };
  });

  useUpdateEffect(() => {
    const nextSteps = steps.map((_, index) => ({
      isReady: index === stepIndex,
      isDone: index < stepIndex,
    }));
    setSteps(nextSteps);

    setTimeout(() => {
      containerRef.current.scrollTo({
        behavior: 'smooth',
        top: containerRef.current.scrollHeight,
      });
    }, 100);
  }, [stepIndex]);

  useUpdateEffect(() => {
    const nextSteps = steps.map((step) => ({
      isReady: false,
      isDone: isSuccess ? true : step.isDone,
    }));
    setSteps(nextSteps);
  }, [isSuccess]);

  const unsubscribe = () => {
    if (subscribeToken.current) {
      EventEmitter.unsubscribe(subscribeToken.current);
      subscribeToken.current = null;
    }
  };

  const doStart = async () => {
    setStarted(true);

    await new Promise((resolve) => {
      subscribeToken.current = EventEmitter.subscribe(`${robot.id}/telemetry/commandAck`, (data) => {
        // 241: MAV_CMD_PREFLIGHT_CALIBRATION
        if (data.command === 241 && data.result === 0) {
          unsubscribe();
          resolve();
        }
      });
      publishCommand(robot, 'calibration/accelerometer/start', [[]]);
    });

    setStepIndex(0);
  };

  const doNext = () => {
    subscribeToken.current = EventEmitter.subscribe(`${robot.id}/telemetry/commandLong`, (data) => {
      // 42429: MAV_CMD_ACCELCAL_VEHICLE_POS
      if (data.command === 42429) {
        unsubscribe();

        // if (data._param1 === stepIndex + 1) {
        if ([1, 2, 3, 4, 5, 6].includes(data._param1)) {
          setStepIndex(stepIndex + 1);
        }
        // 16777215: ACCELCAL_VEHICLE_POS_SUCCESS
        else {
          setSuccess(data._param1 === 16777215);
        }
      }
    });
    publishCommand(robot, 'calibration/accelerometer/next', [[]]);
  };

  const doReboot = () => {
    publishCommand(robot, 'calibration/reboot', [[]]);
    modal.hide();
  };

  const Ready = (
    <div className={cx('status')}>
      <RiLoader2Line size={20} color="white" className={cx('spin')} />
      <div className={cx('text')}>Ready...</div>
    </div>
  );

  const Done = (
    <div className={cx('status')}>
      <RiCheckboxCircleFill size={20} color="#41a3ff" className={cx('message')} />
      <div className={cx('text')}>Done!</div>
    </div>
  );

  return (
    <div ref={containerRef} className={cx('container')}>
      <div className={cx('message')}>Hold still in the current orientation.</div>
      {!isStarted && (
        <button type="button" className={cx('button')} onClick={doStart}>
          Start
        </button>
      )}
      {stepIndex >= 0 && (
        <>
          <div className={cx('submessage')}>Place vehicle level and press Next.</div>
          <img src={require('./01_level.png')} alt="" />
          {steps[0].isReady && Ready}
          {steps[0].isDone && Done}
        </>
      )}
      {stepIndex >= 1 && (
        <>
          <div className={cx('submessage')}>Place vehicle on its LEFT side and press Next.</div>
          <img src={require('./02_left.png')} alt="" />
          {steps[1].isReady && Ready}
          {steps[1].isDone && Done}
        </>
      )}
      {stepIndex >= 2 && (
        <>
          <div className={cx('submessage')}>Place vehicle on its RIGHT side and press Next.</div>
          <img src={require('./03_right.png')} alt="" />
          {steps[2].isReady && Ready}
          {steps[2].isDone && Done}
        </>
      )}
      {stepIndex >= 3 && (
        <>
          <div className={cx('submessage')}>Place vehicle nose DOWN and press Next.</div>
          <img src={require('./04_down.png')} alt="" />
          {steps[3].isReady && Ready}
          {steps[3].isDone && Done}
        </>
      )}
      {stepIndex >= 4 && (
        <>
          <div className={cx('submessage')}>Place vehicle nose UP and press Next.</div>
          <img src={require('./05_up.png')} alt="" />
          {steps[4].isReady && Ready}
          {steps[4].isDone && Done}
        </>
      )}
      {stepIndex >= 5 && (
        <>
          <div className={cx('submessage')}>Place vehicle on its BACK and press Next.</div>
          <img src={require('./06_back.png')} alt="" />
          {steps[5].isReady && Ready}
          {steps[5].isDone && Done}
        </>
      )}
      {isStarted && isSuccess === undefined && !isNaN(stepIndex) && (
        <button type="button" className={cx('button')} onClick={doNext}>
          Next
        </button>
      )}
      {isSuccess && (
        <>
          <div className={cx('status')}>
            <RiCheckboxCircleFill size={20} color="green" className={cx('message')} />
            <div className={cx('text')}>Success!</div>
          </div>
          <div className={cx('message')}>Reboot required to refresh magnetometer calibration parameters.</div>
          <button type="button" className={cx('button')} onClick={doReboot}>
            Reboot
          </button>
        </>
      )}
      {isSuccess === false && (
        <div className={cx('status')}>
          <RiCheckboxCircleFill size={20} color="red" className={cx('message')} />
          <div className={cx('text')}>Failure!</div>
        </div>
      )}
    </div>
  );
};

export default Panel;
